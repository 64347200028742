
import * as THREE from "../../../libs/three.js/build/three.module.js";
import { EventDispatcher } from "../../EventDispatcher.js";

const skyboxGeometry = new THREE.CubeGeometry(100, 100, 100);

let sg = new THREE.SphereGeometry(0.1, 32, 32);
let sm = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0.75 });
let smHovered = new THREE.MeshBasicMaterial({ transparent: true, opacity: 0.75, color: 0xff0000 });

const raycaster = new THREE.Raycaster();
let currentlyHoveredSpot = null;

let previousView = {
	controls: null,
	position: null,
	target: null,
};

class SkyboxPanoramaSpot {

	/**
	 * 
	 * @param {string[]} name name of the spot
	 * @param {{x: number, y: number, z: number}} position 
	 * @param {{w: number, x: number, y: number, z: number}} rotation as a quaternion 
	 * @param {{sourceDir: string, extension?: string, files?: string[]}} images six faces of the cube - to be composed to panorama image
	*/
	constructor(name, position, rotation, images) {
		this.name = name;
		this._position = position;
		this._rotation = rotation;
		this._images = images;
		this.mesh = null;
	}

	get position() {
		const { x, y, z } = this._position;
		return [x, y, z];
	}

	get quaternion() {
		const { x, y, z, w } = this._rotation;
		return new THREE.Quaternion(x, y, z, w).normalize();
	}

	get imageFiles() {
		const { sourceDir, extension, files } = this._images;
		const defaultFilenames = ['px', 'nx', 'py', 'ny', 'pz', 'nz'];

		if (files) {
			return files.map(f => `${sourceDir}/${f}`);
		}

		if (extension) {
			return defaultFilenames.map(f => `${sourceDir}/${f}.${extension}`);
		}

		return defaultFilenames.map(f => `${sourceDir}/${f}.jpg`);
	}
};

export class SkyboxPanoramaTour extends EventDispatcher {

	constructor(viewer, configUrl, params = {}) {
		super();

		this.viewer = viewer;
		this.configUrl = configUrl;

		// skybox = container for displaying the panoramic images
		this.skybox = null;
		// spots = traget points inside the scene. Each spot displays its own panorama
		this.spots = [];
		this.spotsContainer = new THREE.Object3D();

		this._visible = !!params.initiallyVisible;
		this.focusedImage = null;

		viewer.addEventListener("update", () => {
			this.update();
		});
		viewer.inputHandler.addInputListener(this);

		this.addEventListener("mousedown", () => {
			if (currentlyHoveredSpot && currentlyHoveredSpot.spotData) {
				this.focus(currentlyHoveredSpot.spotData);
			}
		});

	};

	set visible(visible) {
		if (this._visible === visible) {
			return;
		}
		for (const spot of this.spots) {
			spot.mesh.visible = visible && (this.focusedImage == null);
		}
		this._visible = visible;
		this.dispatchEvent({
			type: "visibility_changed",
			images: this,
		});
	}

	get visible() {
		return this._visible;
	}

	focus(panoramaSpot) {
		if (this.focusedImage !== null) {
			this.unfocus();
		} else {
			previousView = {
				controls: this.viewer.controls,
				position: this.viewer.scene.view.position.clone(),
				target: this.viewer.scene.view.getPivot(),
			};
		}

		this.viewer.setControls(this.viewer.orbitControls);
		this.viewer.orbitControls.doubleClickZoomEnabled = false;

		for (let spot of this.spots) {
			// make all but the current image/spot visible
			spot.mesh.visible = spot != panoramaSpot;
		}

		this.load(panoramaSpot.imageFiles).then(({ skyboxMaterials, skyboxTextures }) => {
			this.activeSkyboxMaterials = skyboxMaterials;
			this.activeSkyboxTextures = skyboxTextures;

			this.skybox = new THREE.Mesh(skyboxGeometry, skyboxMaterials);
			// "un-flip" the textures (they are flipped becaues they are painted on the "wrong" side of the cube)
			this.skybox.scale.x = -1
			// adjust rotation to compensate for the flipping
			this.skybox.rotation.y = THREE.Math.degToRad(180)
			// now rotate in the direction of the scanner 
			this.skybox.applyQuaternion(panoramaSpot.quaternion)
			// and move the skybox to its correct position
			this.skybox.position.set(...panoramaSpot.position);

			this.dispatchEvent({
				type: 'panorama_spot_entered',
				skybox: this.skybox
			})
		});

		let target = new THREE.Vector3(...panoramaSpot.position);
		let dir = target.clone().sub(this.viewer.scene.view.position).normalize();
		let move = dir.multiplyScalar(0.000001); // TODO useless on smaller scales -> make scalar dynamic
		let newCamPos = target.clone().sub(move);

		this.viewer.scene.view.setView(
			newCamPos,
			target,
			500 // TODO make duration configurable
		);

		this.focusedImage = panoramaSpot;

		this.dispatchEvent({
			type: "focus_changed",
			focussed: true,
		});
	}

	unfocus() {
		for (let spot of this.spots) {
			spot.mesh.visible = true;
		}

		if (this.focusedImage === null) {
			return;
		}

		this.dispatchEvent({
			type: 'panorama_spot_exited',
			skybox: this.skybox
		})
		this.activeSkyboxMaterials.forEach(material => material.dispose())
		this.activeSkyboxTextures.forEach(texture => texture.dispose())

		this.viewer.orbitControls.doubleClockZoomEnabled = true;
		this.viewer.setControls(previousView.controls);

		this.viewer.scene.view.setView(
			previousView.position,
			previousView.target,
			500 // TODO make duration configurable
		);

		this.focusedImage = null;

		this.dispatchEvent({
			type: "focus_changed",
			focussed: false,
		});
	}

	load(files) {
		return new Promise(resolve => {
			const loader = new THREE.TextureLoader();

			const skyboxMaterials = [];
			const skyboxTextures = [];

			for (let i = 0; i < 6; i++) {
				let material = new THREE.MeshBasicMaterial({
					map: null,
					side: THREE.BackSide,
					depthTest: false,
					depthWrite: false,
					color: 0x111111
				});
				skyboxMaterials.push(material);

				loader.load(files[i],
					texture => {
						material.map = texture;
						material.needsUpdate = true;
						material.color.setHex(0xffffff);

						skyboxTextures.push(texture);
					}
				);
			}
			resolve({ skyboxMaterials, skyboxTextures });
		});
	}

	handleHovering() {
		const mouse = this.viewer.inputHandler.mouse;
		const camera = this.viewer.scene.getActiveCamera();
		const { clientWidth, clientHeight } = this.viewer.renderer.domElement;

		const ray = Potree.Utils.mouseToRay(mouse, camera, clientWidth, clientHeight);

		raycaster.ray.copy(ray);
		const intersections = raycaster.intersectObjects(this.spotsContainer.children);

		if (intersections.length === 0) {
			return;
		}

		const intersection = intersections[0];
		currentlyHoveredSpot = intersection.object;
		currentlyHoveredSpot.material = smHovered;
	}

	update() {
		if (currentlyHoveredSpot) {
			currentlyHoveredSpot.material = sm;
			currentlyHoveredSpot = null;
		}
		this.handleHovering();
	}
};


export class SkyboxPanoramaTourLoader {

	static async load(url, viewer, params = {}) {

		if (!params.transform) {
			params.transform = {
				forward: a => a,
			};
		}

		const panoramaTour = new SkyboxPanoramaTour(viewer, url, params);

		const response = await fetch(url);
		const data = await response.json();

		for (let spotData of data.panoramaTour) {

			const { name, position, rotation, images } = spotData;

			let panoramaSpot = new SkyboxPanoramaSpot(name, position, rotation, images);

			// TODO necessary?
			// let xy = params.transform.forward([long, lat]);
			// position = [...xy, alt];

			panoramaTour.spots.push(panoramaSpot);
		}

		SkyboxPanoramaTourLoader.createSceneNodes(panoramaTour, params);

		return panoramaTour;
	}

	static createSceneNodes(panoramaTour, params) {

		for (let panoramaSpot of panoramaTour.spots) {
			// let { longitude, latitude, altitude } = panoramaSpot;
			// let xy = params.transform.forward([longitude, latitude]);

			let mesh = new THREE.Mesh(sg, sm);
			// mesh.position.set(...xy, altitude);
			mesh.position.set(...panoramaSpot.position);
			mesh.scale.set(1, 1, 1);
			mesh.spotData = panoramaSpot;
			mesh.visible = params.initiallyVisible;

			// TODO necessary? The mesh is a sphere...
			mesh.setRotationFromQuaternion(panoramaSpot.quaternion)

			panoramaTour.spotsContainer.add(mesh);

			panoramaSpot.mesh = mesh;
		}
	}
};


