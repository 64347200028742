import * as THREE from '../../libs/three.js/build/three.module.js';
import { Annotation } from '../Annotation.js';
import { EventDispatcher } from '../EventDispatcher.js';
import { Utils } from '../utils.js';

export class AnnotationTool extends EventDispatcher {
    constructor(viewer) {
        super();

        this.viewer = viewer;
        this.renderer = viewer.renderer;
    }

    startInsertion(args = {}, callbacks = {}) {
        let domElement = this.viewer.renderer.domElement;

        const { title, description } = args;

        let annotation = new Annotation({
            position: [0, 0, 0], // NOTE: the position will be updated on drag
            cameraTarget: [0, 0, 0], // NOTE: will be set to the same value as position
            cameraPosition: new THREE.Vector3().copy(
                this.viewer.scene.getActiveCamera().position
            ),
            title,
            description,
            viewer: this.viewer,
        });
        this.dispatchEvent({
            type: 'start_inserting_annotation',
            annotation: annotation,
        });

        const annotations = this.viewer.scene.annotations;
        annotations.add(annotation);

        let insertionCallback = (e) => {
            if (e.button === THREE.MOUSE.LEFT) {
                // NOTE: the finish callback expects the Annotation object as argument (important for the orto app!)
                callbacks.finish(annotation);
            } else if (e.button === THREE.MOUSE.RIGHT) {
                callbacks.cancel();
                annotations.remove(annotation);
            }
            domElement.removeEventListener('mouseup', insertionCallback, true);
        };
        domElement.addEventListener('mouseup', insertionCallback, true);

        let drag = (e) => {
            let intersection = Utils.getMousePointCloudIntersection(
                e.drag.end,
                e.viewer.scene.getActiveCamera(),
                e.viewer,
                e.viewer.scene.pointclouds,
                { pickClipped: true }
            );

            if (intersection) {
                annotation.position.copy(intersection.location);
                annotation.positionMarker.position.copy(intersection.location);
                annotation.cameraTarget.copy(intersection.location);
            }
        };

        let drop = () => {
            annotation.positionMarker.removeEventListener('drag', drag);
            annotation.positionMarker.removeEventListener('drop', drop);
        };

        annotation.positionMarker.addEventListener('drag', drag);
        annotation.positionMarker.addEventListener('drop', drop);

        this.viewer.inputHandler.startDragging(annotation.positionMarker);

        return annotation;
    }

    update() {
        // let camera = this.viewer.scene.getActiveCamera();
        // let domElement = this.renderer.domElement;
        // let measurements = this.viewer.scene.measurements;
        // const renderAreaSize = this.renderer.getSize(new THREE.Vector2());
        // let clientWidth = renderAreaSize.width;
        // let clientHeight = renderAreaSize.height;
    }

    render() {
        //this.viewer.renderer.render(this.scene, this.viewer.scene.getActiveCamera());
    }
}
