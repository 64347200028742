

import * as THREE from "../libs/three.js/build/three.module.js";
import { Action } from "./Actions.js";
import { Utils } from "./utils.js";
import { EventDispatcher } from "./EventDispatcher.js";

export class Annotation extends EventDispatcher {
	constructor(args = {}) {
		super();

		this.viewer = args.viewer;
		this.scene = this.viewer ? this.viewer.scene.scene : null;

		this._title = args.title || 'No Title';
		this._description = args.description || '';
		this.offset = new THREE.Vector3();
		this.uuid = THREE.Math.generateUUID();

		if (!args.position) {
			this.position = null;
		} else if (args.position.x != null) {
			this.position = args.position;
		} else {
			this.position = new THREE.Vector3(...args.position);
		}

		// Create a position marker (sphere), if possible
		if (this.position && this.viewer) {
			// Create the material once
			if (!Annotation.markerMaterial) {
				Annotation.markerMaterial = new THREE.MeshLambertMaterial({
					color: 0xffffff,
					depthTest: false,
					depthWrite: false,
				});
			}
			// Create one point light source for the MeshLambertMaterial to be rendered correctly
			if (!Annotation.light) {
				Annotation.light = new THREE.PointLight(0xffffff, 1.0);
				Annotation.light.position.copy(this.viewer.scene.getActiveCamera().position);
				this.scene.add(Annotation.light);
			}
			this.positionMarker = new THREE.Mesh(new THREE.SphereGeometry(0.4, 32, 32), Annotation.markerMaterial);
			this.positionMarker.position.copy(this.position);
			this.scene.add(this.positionMarker);
			this.viewer.addEventListener("update", this.update.bind(this));
		}

		this.cameraPosition = (args.cameraPosition instanceof Array)
			? new THREE.Vector3().fromArray(args.cameraPosition) : args.cameraPosition;
		this.cameraTarget = (args.cameraTarget instanceof Array)
			? new THREE.Vector3().fromArray(args.cameraTarget) : args.cameraTarget;
		this.radius = args.radius;
		this.view = args.view || null;
		this.actions = args.actions || [];
		this._visible = true;
		this._display = true;
		this._expand = false;
		this.collapseThreshold = [args.collapseThreshold, 100].find(e => e !== undefined);

		this.children = [];
		this.parent = null;
		this.boundingBox = new THREE.Box3();

		this.descriptionVisible = false;
		const togglerIcon = `
			<span class="annotation-description-toggler">
				<img src="${exports.resourcePath}/icons/chevron-down.svg" width="10px">
			</span>`;

		this.domElement = $(`
			<div class="annotation" oncontextmenu="return false;">
				<div class="annotation-titlebar">
					<span class="annotation-title"></span>
					${this._description ? togglerIcon : ''}
				</div>
				<div class="annotation-description">
					<div class="annotation-description-content color-muted">${this._description}</div>
				</div>
			</div>
		`);

		this.elTitlebar = this.domElement.find('.annotation-titlebar');
		this.elTitle = this.elTitlebar.find('.annotation-title');
		// NOTE: appending the title like this instead of setting it in the HTML tags the same way as _description 
		// is some weird hack. This is necessary for potree to render some examples correctly. 
		this.elTitle.append(this._title);
		this.elDescriptionToggler = this.elTitlebar.find('.annotation-description-toggler');
		this.elDescription = this.domElement.find('.annotation-description');
		this.elDescriptionContent = this.elDescription.find('.annotation-description-content');

		this.clickTitle = () => {
			if (this.hasView()) {
				this.moveToTarget();
			}
			this.dispatchEvent({ type: 'click', target: this });
		};

		this.elTitle.click(this.clickTitle);

		this.actions = this.actions.map(a => {
			if (a instanceof Action) {
				return a;
			} else {
				return new Action(a);
			}
		});

		for (let action of this.actions) {
			action.pairWith(this);
		}

		let actions = this.actions.filter(
			a => a.showIn === undefined || a.showIn.includes('scene'));

		for (let action of actions) {
			let elButton = $(`<img src="${action.icon}" class="annotation-action-icon">`);
			this.elTitlebar.append(elButton);
			elButton.click(() => action.onclick({ annotation: this }));
		}

		this.elDescriptionToggler.hover(
			e => this.elDescriptionToggler.css('opacity', '0.5'),
			e => this.elDescriptionToggler.css('opacity', '1')
		);
		// NOTE: use arrow function here to correctly bind 'this'
		this.elDescriptionToggler.click(() => this.toggleDescription());
		this.domElement.on('touchstart', () => this.toggleDescription());

		setTimeout(() => this.toggleDescription(), 1400);

		this.display = false;
	}

	update() {
		const camera = this.viewer.scene.getActiveCamera();
		const renderAreaSize = this.viewer.renderer.getSize(new THREE.Vector2());
		const { width: clientWidth, height: clientHeight } = renderAreaSize;
		const distance = camera.position.distanceTo(this.positionMarker.getWorldPosition(new THREE.Vector3()));
		const pr = Utils.projectedRadius(1, camera, distance, clientWidth, clientHeight);
		const scale = (15 / pr);
		this.positionMarker.scale.set(scale, scale, scale);

		Annotation.light.position.copy(camera.position);
	}

	get visible() {
		return this._visible;
	}

	set visible(value) {
		if (this._visible === value) {
			return;
		}

		this._visible = value;

		//this.traverse(node => {
		//	node.display = value;
		//});

		this.dispatchEvent({
			type: 'visibility_changed',
			annotation: this
		});
	}

	get display() {
		return this._display;
	}

	set display(display) {
		if (this._display === display) {
			return;
		}

		this._display = display;

		if (display) {
			// this.domElement.fadeIn(200);
			this.domElement.show();
		} else {
			// this.domElement.fadeOut(200);
			this.domElement.hide();
		}
	}

	get expand() {
		return this._expand;
	}

	set expand(expand) {
		if (this._expand === expand) {
			return;
		}

		if (expand) {
			this.display = false;
		} else {
			this.display = true;
			this.traverseDescendants(node => {
				node.display = false;
			});
		}

		this._expand = expand;
	}

	get title() {
		return this._title;
	}

	set title(title) {
		if (this._title === title) {
			return;
		}

		this._title = title;
		this.elTitle.empty();
		this.elTitle.append(this._title);

		this.dispatchEvent({
			type: "annotation_changed",
			annotation: this,
		});
	}

	get description() {
		return this._description;
	}

	set description(description) {
		if (this._description === description) {
			return;
		}

		this._description = description;

		this.elDescriptionContent.empty();
		this.elDescriptionContent.append(this._description);

		this.dispatchEvent({
			type: "annotation_changed",
			annotation: this,
		});
	}

	add(annotation) {
		if (!this.children.includes(annotation)) {
			this.children.push(annotation);
			annotation.parent = this;

			let descendants = [];
			annotation.traverse(a => { descendants.push(a); });

			for (let descendant of descendants) {
				let c = this;
				while (c !== null) {
					c.dispatchEvent({
						'type': 'annotation_added',
						'annotation': descendant
					});
					c = c.parent;
				}
			}
		}
	}

	level() {
		if (this.parent === null) {
			return 0;
		} else {
			return this.parent.level() + 1;
		}
	}

	hasChild(annotation) {
		return this.children.includes(annotation);
	}

	remove(annotation) {
		if (this.hasChild(annotation)) {
			annotation.removeAllChildren();
			annotation.dispose();
			this.children = this.children.filter(e => e !== annotation);
			annotation.parent = null;
		}
	}

	removeAllChildren() {
		this.children.forEach((child) => {
			if (child.children.length > 0) {
				child.removeAllChildren();
			}

			this.remove(child);
		});
	}

	updateBounds() {
		let box = new THREE.Box3();

		if (this.position) {
			box.expandByPoint(this.position);
		}

		for (let child of this.children) {
			child.updateBounds();

			box.union(child.boundingBox);
		}

		this.boundingBox.copy(box);
	}

	traverse(handler) {
		let expand = handler(this);

		if (expand === undefined || expand === true) {
			for (let child of this.children) {
				child.traverse(handler);
			}
		}
	}

	traverseDescendants(handler) {
		for (let child of this.children) {
			child.traverse(handler);
		}
	}

	flatten() {
		let annotations = [];

		this.traverse(annotation => {
			annotations.push(annotation);
		});

		return annotations;
	}

	descendants() {
		let annotations = [];

		this.traverse(annotation => {
			if (annotation !== this) {
				annotations.push(annotation);
			}
		});

		return annotations;
	}

	toggleDescription() {
		if (!this._description) return;

		// Adjust styles
		if (!this.descriptionVisible) {
			this.domElement.css('z-index', '1000');
			this.domElement.css('max-width', '210px');

			this.elDescription.css('max-width', '210px');
			this.elDescription.delay(300).queue(next => {
				const padding = 24;
				this.elDescription.css('max-height', this.elDescriptionContent.height() + padding);
				next();
			});

			this.elDescriptionToggler.css('transform', 'rotate(180deg)')
		} else {
			this.domElement.css('z-index', '100');
			this.domElement.delay(300).queue(next => {
				const padding = 32;
				const maxWidth = this.elTitle.width() + this.elDescriptionToggler.width() + padding;
				this.domElement.css('max-width', maxWidth);
				next();
			});

			this.elDescription.css('max-height', '0');

			this.elDescriptionToggler.css('transform', 'rotate(0deg)')
		}

		// Update state
		this.descriptionVisible = !this.descriptionVisible;
	}

	hasView() {
		let hasPosTargetView = this.cameraTarget.x != null;
		hasPosTargetView = hasPosTargetView && this.cameraPosition.x != null;

		let hasRadiusView = this.radius !== undefined;

		let hasView = hasPosTargetView || hasRadiusView;

		return hasView;
	};

	moveToTarget() {
		if (!this.hasView()) {
			return;
		}

		let view = this.scene.view;
		let animationDuration = 500;
		let easing = TWEEN.Easing.Quartic.Out;

		let endTarget;
		if (this.cameraTarget) {
			endTarget = this.cameraTarget;
		} else if (this.position) {
			endTarget = this.position;
		} else {
			endTarget = this.boundingBox.getCenter(new THREE.Vector3());
		}

		if (this.cameraPosition) {
			let endPosition = this.cameraPosition;

			Utils.moveTo(this.scene, endPosition, endTarget);
		} else if (this.radius) {
			let direction = view.direction;
			let endPosition = endTarget.clone().add(direction.multiplyScalar(-this.radius));
			let startRadius = view.radius;
			let endRadius = this.radius;

			{ // animate camera position
				let tween = new TWEEN.Tween(view.position).to(endPosition, animationDuration);
				tween.easing(easing);
				tween.start();
			}

			{ // animate radius
				let t = { x: 0 };

				let tween = new TWEEN.Tween(t)
					.to({ x: 1 }, animationDuration)
					.onUpdate(function () {
						view.radius = this.x * endRadius + (1 - this.x) * startRadius;
					});
				tween.easing(easing);
				tween.start();
			}
		}
	};

	dispose() {
		if (this.domElement.parentElement) {
			this.domElement.parentElement.removeChild(this.domElement);
		}

		if (this.positionMarker) {
			this.positionMarker.parent.remove(this.positionMarker);
			this.viewer.removeEventListener("update", this.update);
		}
	};

	toString() {
		return 'Annotation: ' + this._title;
	}
};
